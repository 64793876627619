<template>
  <div>
    <EditButton :disabled="!$atividades.permissoes.includes(14)" @func="modalEditarPortador = true"></EditButton>
    <SideBar
      title="Editar portador"
      @hide="cancelar"
      :active="modalEditarPortador"
      size="small"
      textSucces="Atualizar"
      :item="portador"
      :disabled="!$atividades.permissoes.includes(15)"
      @excluir="exluirPortador()"
      @status="mudarStatusPortador()"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              name="nome"
              v-validate="'required'"
              label-placeholder="Nome"
              v-model="portador.nome"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarPortador",
  props: { portador: Object },
  data() {
    return {
      modalEditarPortador: false,
      error: false,
      originalData: {}
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.portador);
    this.originalData = this.portador;
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarPortador();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarPortador() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.$vs.loading();
          try {
            const res = await this.$http.put(`portador/${this.portador.id}`, this.portador);
            let logData =  {
              id_colaborador: localStorage.getItem("id"),
              funcao: 'atualizar',
              setor: 'comercial',
              ip: window.localStorage.getItem("ip"),
              texto: 'Edição do portador N°' + res.id}
            await this.$logger(logData)
            this.modalEditarPortador = false;
          } catch (err) {
            const error = this.$httpErrors(err);
            this.$vs.notify(error);
          } finally {
            this.$vs.loading.close();
          }
        }
      });
    },
    async exluirPortador() {
      this.$vs.loading();
      try {
        await this.$http.delete(`portador/${this.portador.id}`);
         let logData =  {
            id_colaborador: localStorage.getItem("id"),
            funcao: 'excluir',
            setor: 'comercial',
            ip: window.localStorage.getItem("ip"),
            texto: 'Excluir portador N°' + this.portador.id}
          await this.$logger(logData)
        this.modalEditarPortador = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusPortador() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`portador/${this.portador.id}`, {
          lixeira: !this.portador.lixeira
        });
         let logData =  {
            id_colaborador: localStorage.getItem("id"),
            funcao: 'atualizar',
            setor: 'comercial',
            ip: window.localStorage.getItem("ip"),
            texto: 'Mudar status do portador N°' + res.id}
          await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalEditarPortador = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    cancelar() {
      Object.assign(this.portador, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarPortador = false;
    }
  },
  components: components
};
</script>

<style></style>
